import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Headroom from 'react-headroom';
import { Heart, ShoppingBag, List, X } from 'phosphor-react';
import FavoritesModal from './FavoritesModal';
import useFavorites from '../hooks/useFavorites';
import { COMPANY } from '../config/seo';
import PropTypes from 'prop-types';
import '../styles/components/Header.css';

const NavLink = React.memo(({ path, label, isActive, onClick }) => (
  <Link
    to={path}
    className={`nav-link ${isActive ? 'active' : ''}`}
    onClick={onClick}
  >
    {label}
  </Link>
));

NavLink.displayName = 'NavLink';

NavLink.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Header = () => {
  const location = useLocation();
  const { favorites } = useFavorites();
  const [showFavorites, setShowFavorites] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const navLinks = [
    { path: '/about', label: 'About' },
    { path: '/contact', label: 'Contact' },
  ];

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const closeMenu = () => {
    setShowMobileMenu(false);
  };

  const isActiveLink = (path) => location.pathname === path;

  return (
    <>
      <HelmetProvider>
        <title>{`${COMPANY.name} - Best Deals from Coles and Woolworths`}</title>
        <meta
          name="description"
          content="Discover half-price grocery specials from Coles and Woolworths instantly. Create your half-price shopping list, maximize savings and time."
        />
      </HelmetProvider>
      <Headroom
        pinStart={100}
        calcHeightOnResize={true}
        disableInlineStyles={true}
        upTolerance={10}
        downTolerance={10}
      >
        <header className="header">
          <div className="header-content">
            <Link to="/" className="logo" onClick={closeMenu}>
              <ShoppingBag size={32} weight="fill" />
              <span className="logo-text">
                <span className="logo-half">Half</span>
                <span className="logo-price">Price</span>
                <span className="logo-grocery">GROCERY</span>
              </span>
            </Link>

            <nav
              className={`main-nav ${showMobileMenu ? 'show' : ''}`}
              role="navigation"
              aria-label="Main navigation"
            >
              {navLinks.map(({ path, label }) => (
                <NavLink
                  key={path}
                  path={path}
                  label={label}
                  isActive={isActiveLink(path)}
                  onClick={closeMenu}
                />
              ))}
            </nav>

            <div className="user-actions">
              <button
                className="heart-icon"
                onClick={() => setShowFavorites(true)}
                aria-label="View favorites"
              >
                <Heart size={32} weight="fill" />
                {favorites.length > 0 && (
                  <span
                    className="favorites-count"
                    aria-label={`${favorites.length} favorites`}
                  >
                    {favorites.length}
                  </span>
                )}
              </button>

              <button
                className="mobile-menu-toggle"
                onClick={toggleMobileMenu}
                aria-label={showMobileMenu ? 'Close menu' : 'Open menu'}
                aria-expanded={showMobileMenu}
              >
                {showMobileMenu ? <X size={32} /> : <List size={32} />}
              </button>
            </div>
          </div>
        </header>
      </Headroom>

      <FavoritesModal
        isOpen={showFavorites}
        onClose={() => setShowFavorites(false)}
      />
    </>
  );
};

Header.displayName = 'Header';

export default React.memo(Header);
