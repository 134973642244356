import { useMemo } from 'react';
import { isFromLastWeek } from '../utils/dateUtils';
import wooliesLogo from '../assets/woolies.png';
import colesLogo from '../assets/coles.png';

export const usePriceCalculations = (product) => {
  const getBrandLogo = (brand) => {
    switch (brand?.toLowerCase()) {
      case 'woolies':
        return { src: wooliesLogo, alt: 'Woolworths' };
      case 'coles':
        return { src: colesLogo, alt: 'Coles' };
      default:
        return null;
    }
  };

  return useMemo(() => {
    // Handle null values
    const price = product?.price || 0;
    const originalPrice = product?.originalPrice || price;
    const savings = product?.savings || 0;

    // Calculate safely
    const savingsPercentage =
      originalPrice > 0 ? (savings / originalPrice) * 100 : 0;
    const isHalfPrice = savingsPercentage >= 49.5;

    // Use product.updatedAt so the sale info reflects current data even for old favorites
    const isCurrentWeek = !isFromLastWeek(product?.updatedAt);

    return {
      savings: {
        amount: savings.toFixed(2),
        percentage: savingsPercentage.toFixed(0),
        isHalfPrice: isHalfPrice && isCurrentWeek,
      },
      formattedPrice: price.toFixed(2),
      formattedOriginalPrice: originalPrice.toFixed(2),
      // If not on half price or not current, or no savings/originalPrice, show only the original price
      showOriginalPrice:
        !isHalfPrice || !isCurrentWeek || !savings || !originalPrice,
      brandLogo: getBrandLogo(product?.brand),
    };
  }, [product]);
};
