import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/FavoritesTabs.css';

const FavoritesTabs = React.memo(
  ({ activeTab, setActiveTab, favoritesCount, allTimeFavoritesCount }) => (
    <h2 className="favorites-tabs">
      <button
        className={`favorites-tab ${activeTab === 'thisWeek' ? 'active' : ''}`}
        onClick={() => setActiveTab('thisWeek')}
        aria-label="View this week's favorites"
        aria-current={activeTab === 'thisWeek' ? 'page' : null}
        id="thisWeekFavorites"
      >
        This Week&apos;s Favorites{' '}
        <span className="tab-count">{favoritesCount}</span>
      </button>
      <button
        className={`favorites-tab ${activeTab === 'allTime' ? 'active' : ''}`}
        onClick={() => setActiveTab('allTime')}
        aria-label="View all-time favorites"
        aria-current={activeTab === 'allTime' ? 'page' : null}
        id="allTimeFavorites"
      >
        All-Time Favorites{' '}
        <span className="tab-count">{allTimeFavoritesCount}</span>
      </button>
    </h2>
  )
);

FavoritesTabs.displayName = 'FavoritesTabs';

FavoritesTabs.propTypes = {
  activeTab: PropTypes.oneOf(['thisWeek', 'allTime']).isRequired,
  setActiveTab: PropTypes.func.isRequired,
  favoritesCount: PropTypes.number.isRequired,
  allTimeFavoritesCount: PropTypes.number.isRequired,
};

export default FavoritesTabs;
