const COMPANY = {
  name: 'Half-Price Grocery',
  domain: 'halfpricegrocery.com',
  baseUrl: 'https://halfpricegrocery.com',
  contact: {
    email: 'jsoncp@proton.me',
    type: 'customer support',
  },
  social: {
    github: 'https://github.com/jayson-panganiban',
    linkedin: 'https://www.linkedin.com/in/jayson-panganiban',
  },
};

const formatBrandName = (brand) =>
  brand.charAt(0).toUpperCase() + brand.slice(1);

const createStructuredData = {
  base: () => ({
    '@type': 'Organization',
    name: COMPANY.name,
    url: COMPANY.baseUrl,
    logo: `${COMPANY.baseUrl}/logo.png`,
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: COMPANY.contact.type,
      email: COMPANY.contact.email,
    },
    sameAs: Object.values(COMPANY.social),
  }),

  product: (product, brand) => ({
    '@type': 'Product',
    name: product.name,
    description: `${product.name} on sale at ${brand}`,
    image: product.image,
    offers: {
      '@type': 'Offer',
      price: product.price,
      priceCurrency: 'AUD',
      availability: 'https://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: brand,
      },
    },
  }),
};

const SEO = {
  getBrandTitle: (brand) =>
    `${formatBrandName(brand)} Half-Price Specials | ${COMPANY.name}`,
  getBrandDescription: (brand) =>
    `Browse current half-price specials at ${formatBrandName(
      brand
    )}. Save on your weekly grocery shopping with our curated list of Australian supermarket discounts.`,
  getStructuredData: (products) => ({
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: products.map((product, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: createStructuredData.product(product, product.brand),
    })),
  }),

  home: {
    title: `${COMPANY.name} | Current Half-Price Specials at Coles & Woolworths`,
    description:
      'Find the latest half-price specials and deals at Coles and Woolworths. Save up to 50% on your weekly grocery shopping with our curated list of Australian supermarket discounts.',
    url: COMPANY.baseUrl,
  },

  about: {
    title: `About | ${COMPANY.name} - Australian Grocery Deals`,
    description: `Discover how ${COMPANY.name} helps you find the best half-price specials from Coles and Woolworths. Save time and money on your weekly grocery shopping in Australia.`,
    url: `${COMPANY.baseUrl}/about`,
    structuredData: {
      ...createStructuredData.base(),
      description:
        'Finding the best grocery deals across Australia, one click at a time!',
    },
  },

  contact: {
    title: `Contact | ${COMPANY.name} - Get in Touch`,
    description: `Connect with ${COMPANY.name}. Share your feedback, suggestions, or inquiries about Australian grocery deals and specials.`,
    url: `${COMPANY.baseUrl}/contact`,
    structuredData: {
      '@type': 'ContactPage',
      name: `Contact ${COMPANY.name}`,
      description: `Get in touch with ${COMPANY.name}. We value your feedback on Australian grocery deals.`,
      url: `${COMPANY.baseUrl}/contact`,
      mainEntity: createStructuredData.base(),
    },
  },

  category: {
    getTitle: (category, brand) =>
      `${category} Deals at ${formatBrandName(brand)} | ${
        COMPANY.name
      } Australia`,
    getDescription: (category, brand) =>
      `Save on ${category} at ${formatBrandName(
        brand
      )}. Browse current half-price deals and discounts up to 50% off. Updated daily with the latest Australian grocery specials.`,
    getUrl: (brand, category) =>
      `${COMPANY.baseUrl}/${brand}/${category.toLowerCase()}`,
    getStructuredData: (category, brand, products) => ({
      '@type': 'CollectionPage',
      name: `${category} Deals at ${brand}`,
      description: `Current half-price ${category} deals at ${brand}`,
      offers: products.map((product) => ({
        '@type': 'Offer',
        itemOffered: createStructuredData.product(product, brand),
      })),
    }),
  },
};

export { COMPANY, SEO };
