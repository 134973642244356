import React from 'react';
import { Link } from 'react-router-dom';
import { COMPANY } from '../config/seo';
import '../styles/components/Footer.css';

const Footer = () => (
  <footer className="footer" role="contentinfo">
    <div className="footer-container">
      <div className="footer-content">
        <p className="copyright">
          &copy; {new Date().getFullYear()} {COMPANY.name}
        </p>
        <nav className="footer-nav">
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </nav>
        <p className="disclaimer">
          This application references third-party brand names and logos solely
          for informational purposes. All trademarks belong to their respective
          owners.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
