import React from 'react';
import '../styles/components/LoadingSpinner.css';

const LoadingSpinner = () => (
  <div
    className="loading-spinner-container"
    role="status"
    aria-busy="true"
    aria-live="polite"
  >
    <div className="loading-spinner" aria-hidden="true" />
    <p className="loading-text">Loading...</p>
  </div>
);

export default LoadingSpinner;
