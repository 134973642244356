import React, { useContext, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { Heart, ChartLine } from 'phosphor-react';
import { FavoritesContext } from '../context/FavoritesContext';
import PriceHistoryModal from './PriceHistoryModal';
import { usePriceCalculations } from '../hooks/usePriceCalculations';
import '../styles/components/ProductCard.css';

const ProductCard = memo(({ product }) => {
  const { toggleFavorite, isFavorite } = useContext(FavoritesContext);
  const [showPriceHistory, setShowPriceHistory] = useState(false);

  const {
    savings,
    formattedPrice,
    formattedOriginalPrice,
    showOriginalPrice,
    brandLogo,
  } = usePriceCalculations(product);

  return (
    <>
      <HelmetProvider>
        <meta
          name="description"
          content={`${product.name} on sale at ${product.brand}. Save ${savings.percentage}% now!`}
        />
      </HelmetProvider>
      <article className="product-card" data-half-price={savings.isHalfPrice}>
        <div
          className="product-image-container"
          role="img"
          aria-label={`Product image for ${product.name}`}
        >
          {savings.isHalfPrice && (
            <div
              className="savings-badge"
              role="status"
              aria-label={`Save ${savings.percentage}% off`}
            >
              <span>-{savings.percentage}%</span>
            </div>
          )}
          <a
            href={product.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`View ${product.name} at ${product.brand}`}
          >
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
              loading="lazy"
            />
            {brandLogo ? (
              <img
                src={brandLogo.src}
                alt={brandLogo.alt}
                className="brand-logo"
                loading="lazy"
              />
            ) : (
              <div
                className="brand-logo-placeholder"
                role="presentation"
                aria-label={`${product.brand} logo not available`}
              />
            )}
          </a>
        </div>
        <div className="product-info-container" role="contentinfo">
          <h2 className="product-name">{product.name}</h2>
          <div
            className="price-container"
            role="region"
            aria-label="Product pricing"
          >
            <p className="current-price" aria-label="Current price">
              ${savings.isHalfPrice ? formattedPrice : formattedOriginalPrice}
            </p>
            {!showOriginalPrice && (
              <p className="original-price" aria-label="Original price">
                ${formattedOriginalPrice}
              </p>
            )}
          </div>
          <div
            className="price-and-actions-container"
            role="group"
            aria-label="Product actions"
          >
            {product.pricePerUnit && (
              <p className="price-per-unit" aria-label="Price per unit">
                {product.pricePerUnit}
              </p>
            )}
            <div
              className="product-actions"
              role="toolbar"
              aria-label="Product interaction buttons"
            >
              <button
                className="price-history-button"
                onClick={() => setShowPriceHistory(true)}
                aria-label="View price history"
              >
                <ChartLine size={24} aria-hidden="true" />
              </button>
              <button
                className="favorite-button"
                onClick={() => toggleFavorite(product)}
                aria-label={`${
                  isFavorite(product._id) ? 'Remove from' : 'Add to'
                } favorites`}
                aria-pressed={isFavorite(product._id)}
              >
                <Heart
                  size={24}
                  weight={isFavorite(product._id) ? 'fill' : 'regular'}
                  className={
                    isFavorite(product._id)
                      ? 'heart-icon filled'
                      : 'heart-icon regular'
                  }
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          {showPriceHistory && (
            <PriceHistoryModal
              product={product}
              onClose={() => setShowPriceHistory(false)}
            />
          )}
        </div>
      </article>
    </>
  );
});

ProductCard.displayName = 'ProductCard';

ProductCard.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    pricePerUnit: PropTypes.string,
  }).isRequired,
};

export default ProductCard;
