import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import ProductGrid from './ProductGrid';
import CloseButton from './CloseButton';
import FavoritesTabs from './FavoritesTabs';
import useFavorites from '../hooks/useFavorites';
import '../styles/components/FavoritesModal.css';

Modal.setAppElement('#root');

const FavoritesModal = ({ isOpen, onClose }) => {
  const { favorites, allTimeFavorites } = useFavorites();
  const [activeTab, setActiveTab] = useState('thisWeek');

  const sortedProducts = useMemo(
    () => ({
      thisWeek: [...favorites].sort(
        (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
      ),
      allTime: [...allTimeFavorites].sort(
        (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
      ),
    }),
    [favorites, allTimeFavorites]
  );

  const currentProducts = sortedProducts[activeTab];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Your Favorite Products"
      className="favorites-modal"
      overlayClassName="favorites-modal-overlay"
    >
      <header className="favorites-header">
        <FavoritesTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          favoritesCount={favorites.length}
          allTimeFavoritesCount={allTimeFavorites.length}
        />
        <CloseButton onClick={onClose} />
      </header>

      <main className="favorites-content">
        {currentProducts.length > 0 ? (
          <ProductGrid products={currentProducts} />
        ) : (
          <p className="no-favorites" role="status">
            {`No ${
              activeTab === 'thisWeek' ? 'weekly' : 'all-time'
            } favorites yet`}
          </p>
        )}
      </main>
    </Modal>
  );
};

FavoritesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(FavoritesModal);
