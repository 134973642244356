import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PriceHistoryChart from './PriceHistoryChart';
import CloseButton from './CloseButton';
import '../styles/components/PriceHistoryModal.css';

const PriceHistoryModal = ({ product, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    setIsVisible(true);

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  // Touch event handlers for mobile swipe
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchEnd - touchStart;
    const isDownSwipe = distance > 100;

    if (isDownSwipe) {
      handleClose();
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  return (
    <div
      className={`price-history-modal ${isVisible ? 'visible' : ''}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      role="dialog"
      aria-label="Price History"
    >
      <div className="price-history-content">
        <div className="modal-header">
          <div className="swipe-indicator" role="presentation" />
          <CloseButton onClick={handleClose} />
        </div>
        <h2 className="price-history-title">
          {product.name}
          <br />
          <span className="price-history-subtitle">Price History</span>
        </h2>
        <PriceHistoryChart
          priceHistory={product.priceHistory}
          originalPrice={product.originalPrice}
        />
      </div>
    </div>
  );
};

PriceHistoryModal.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    priceHistory: PropTypes.arrayOf(
      PropTypes.shape({
        timestamp: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      })
    ).isRequired,
    originalPrice: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(PriceHistoryModal);
