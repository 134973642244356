import React, { createContext, useState, useEffect, useCallback } from 'react';
import { isFromLastWeek } from '../utils/dateUtils';
import PropTypes from 'prop-types';

export const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  // Grab favorites from localStorage, filtering as needed.
  const initializeState = (key, filterFn = () => true) => {
    const stored = localStorage.getItem(key);
    const parsed = stored ? JSON.parse(stored) : [];
    return parsed.filter((item) => item.isFavorite).filter(filterFn);
  };

  // Current favorites are recent ones (filtered) and all-time favorites are not filtered.
  const [favorites, setFavorites] = useState(() =>
    initializeState(
      'favorites',
      (favorite) => !isFromLastWeek(favorite.dateAdded)
    )
  );
  const [allTimeFavorites, setAllTimeFavorites] = useState(() =>
    initializeState('allTimeFavorites')
  );

  // Update localStorage when favorites change.
  const updateLocalStorage = useCallback(() => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
    localStorage.setItem('allTimeFavorites', JSON.stringify(allTimeFavorites));
  }, [favorites, allTimeFavorites]);

  useEffect(() => {
    updateLocalStorage();
  }, [updateLocalStorage]);

  // Toggle a product as favorited or remove it if it's already favorited.
  const toggleFavorite = useCallback(
    (product) => {
      const productWithMeta = {
        ...product,
        isFavorite: true,
        dateAdded: new Date().toISOString(),
      };

      const shouldRemove = [...favorites, ...allTimeFavorites].some(
        (fav) => fav._id === product._id
      );

      if (shouldRemove) {
        setFavorites((prev) => prev.filter((fav) => fav._id !== product._id));
        setAllTimeFavorites((prev) =>
          prev.filter((fav) => fav._id !== product._id)
        );
      } else {
        setFavorites((prev) => [...prev, productWithMeta]);
        setAllTimeFavorites((prev) => [...prev, productWithMeta]);
      }
    },
    [favorites, allTimeFavorites]
  );

  // Check if a given product (by id) is in favorites.
  const isFavorite = useCallback(
    (productId) =>
      [...favorites, ...allTimeFavorites].some((fav) => fav._id === productId),
    [favorites, allTimeFavorites]
  );

  // Provide favorites and functions via context.
  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        allTimeFavorites,
        toggleFavorite,
        isFavorite,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

FavoritesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
