import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { X } from 'phosphor-react';
import '../styles/components/CloseButton.css';

const CloseButton = ({ onClick }) => {
  const handleClick = useCallback(
    (event) => {
      onClick(event);
    },
    [onClick]
  );

  return (
    <button
      className="close-button"
      onClick={handleClick}
      aria-label="Close"
      type="button"
    >
      <X size={24} />
    </button>
  );
};

CloseButton.displayName = 'CloseButton';

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
