import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString('en-AU', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const PriceHistoryChart = ({ priceHistory, originalPrice }) => {
  const isMobile = window.innerWidth <= 768;

  // Filter out any entries with null or undefined prices
  const validPriceHistory = useMemo(
    () =>
      priceHistory.filter((entry) => entry && typeof entry.price === 'number'),
    [priceHistory]
  );

  // Optimize data for mobile displays
  const displayHistory = useMemo(
    () => (isMobile ? validPriceHistory.slice(-6) : validPriceHistory),
    [isMobile, validPriceHistory]
  );

  const data = useMemo(
    () => ({
      labels: displayHistory.map((entry) => formatDate(entry.timestamp)),
      datasets: [
        {
          label: 'Price',
          data: displayHistory.map((entry) => entry.price),
          fill: true,
          backgroundColor: 'rgba(255, 187, 0, 0.2)',
          borderColor: 'rgba(255, 187, 0, 1)',
          tension: 0.4,
          pointBackgroundColor: 'rgba(255, 187, 0, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(255, 187, 0, 1)',
        },
      ],
    }),
    [displayHistory]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: 'rgba(28, 39, 76, 0.8)',
          titleFont: { size: 14, weight: 'bold' },
          bodyFont: { size: 12 },
          callbacks: {
            label: (context) => `Price: ${context.parsed.y.toFixed(2)}`,
          },
        },
      },
      scales: {
        x: {
          grid: { display: false },
          ticks: { maxRotation: 45, minRotation: 45 },
        },
        y: {
          beginAtZero: false,
          ticks: {
            callback: (value) => `${value.toFixed(2)}`,
          },
        },
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
    }),
    []
  );

  const getLowestPrice = useCallback(() => {
    if (validPriceHistory.length === 0) return '0.00';
    return Math.min(...validPriceHistory.map((entry) => entry.price)).toFixed(
      2
    );
  }, [validPriceHistory]);

  const getHighestPrice = useCallback(() => {
    if (validPriceHistory.length === 0) return '0.00';
    return Math.max(...validPriceHistory.map((entry) => entry.price)).toFixed(
      2
    );
  }, [validPriceHistory]);

  // Format originalPrice safely
  const formattedOriginalPrice =
    typeof originalPrice === 'number' ? originalPrice.toFixed(2) : '0.00';

  return (
    <div className="price-history-chart">
      <div className="chart-container">
        {validPriceHistory.length > 0 ? (
          <Line data={data} options={options} />
        ) : (
          <div className="no-history-message">No price history available</div>
        )}
      </div>
      <div className="price-summary">
        <div className="summary-item">
          <span className="summary-label">Lowest: </span>
          <span className="summary-value">${getLowestPrice()}</span>
        </div>
        <div className="summary-item">
          <span className="summary-label">Highest: </span>
          <span className="summary-value">${getHighestPrice()}</span>
        </div>
        <div className="summary-item">
          <span className="summary-label">Original: </span>
          <span className="summary-value">${formattedOriginalPrice}</span>
        </div>
      </div>
    </div>
  );
};

PriceHistoryChart.propTypes = {
  priceHistory: PropTypes.arrayOf(
    PropTypes.shape({
      timestamp: PropTypes.string.isRequired,
      price: PropTypes.number,
      savings: PropTypes.number,
      originalPrice: PropTypes.number,
      pricePerUnit: PropTypes.string,
    })
  ).isRequired,
  originalPrice: PropTypes.number,
};

// Set default props
PriceHistoryChart.defaultProps = {
  originalPrice: 0,
  priceHistory: [],
};

export default React.memo(PriceHistoryChart);
