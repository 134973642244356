import React from 'react';
import PropTypes from 'prop-types';
import ProductCard from './ProductCard';
import '../styles/components/ProductGrid.css';

const ProductGrid = ({ products }) => (
  <div className="product-grid" role="grid" aria-label="Product listings">
    {products.map((product) => (
      <ProductCard key={product._id} product={product} />
    ))}
  </div>
);

ProductGrid.propTypes = {
  products: PropTypes.array.isRequired,
};

export default React.memo(ProductGrid);
